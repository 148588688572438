<template>
    <div>
        <el-row>
            <el-form v-model="condition" :inline="true" label-position="right" label-width="80px">
                <el-form-item label="姓名" prop="studentName" size="mini">
                    <el-input v-model="condition.studentName" class="condition-input" placeholder="姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="telephone" size="mini">
                    <el-input v-model="condition.telephone" class="condition-input" filterable
                              placeholder="手机号"></el-input>
                </el-form-item>
                <el-form-item label="咨询科目" prop="subjectIds" size="mini">
                    <el-select v-model="condition.subjectIds" class="condition-select" placeholder="请选择">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in subjectList" :key="item.id" :label="item.subjectName"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

        <el-form-item label="唯一配置" prop="possessId" size="mini">
          <el-select v-model="condition.possessId" class="condition-select" filterable placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in possessList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传时间" prop="uploadTime" size="mini">
          <el-date-picker v-model="condition.uploadTime" type="datetimerange" range-separator="至"
                          start-placeholder="上传日期开始" :default-time="['00:00:00', '23:59:59']"
                          end-placeholder="上传日期结束">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="放弃时间" prop="backStoreTime" size="mini">
          <el-date-picker v-model="condition.backStoreTimeStr" type="datetimerange" range-separator="至"
                          start-placeholder="放弃日期开始" :default-time="['00:00:00', '23:59:59']"
                          end-placeholder="放弃日期结束">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="doSearch">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
      <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="名片信息" name="first" >
              <el-row>
                  <el-button-group :style="{marginBottom:'10px'}">
                      <el-button v-permission="'crm:cardSea:allot'" type="primary" size="mini" @click="toAllot">分配</el-button>
                      <!--        <el-button v-permission="'crm:cardSea:draw'" type="primary" size="mini" @click="drawOppor">领取</el-button>-->
                      <el-button v-permission="'crm:cardSea:import'" type="primary" size="mini" @click="importOppor">导入
                      </el-button>
                      <el-button v-permission="'crm:cardSea:delete'" type="primary" size="mini" @click="toRecycleBin">删除
                      </el-button>
                      <el-button v-permission="'crm:cardSea:showTelephone'" type="primary" size="mini" @click="showTelephone">
                          查看手机
                      </el-button>
                      <el-button v-permission="'crm:cardSea:updatePossessId'" type="primary" size="mini"
                                 @click="toUpdatePossess">修改唯一配置
                      </el-button>
                  </el-button-group>
              </el-row>
              <el-table :data="opporTableData" style="width: 100%" stripe fit @select="selectRow" @select-all="selectAll"
                        max-height="800"
                        @selection-change="selectChange" v-loading="loading">
                  <el-table-column align="center" type="selection" fixed="left"></el-table-column>
<!--                  <el-table-column align="center" prop="subjectIds" label="咨询科目">-->
<!--                      <template slot-scope="scope">-->
<!--                          <div v-for="item in subjectList" v-if="item.id+'' === scope.row.subjectIds">{{item.subjectName}}</div>-->
<!--                      </template>-->
<!--                  </el-table-column>-->
                  <el-table-column v-for="(col,index) in columns" :prop="col.name"
                                   :label="col.label" width="150" align="center">
                      <template slot-scope="scope">
                          <div v-if="col.name === 'subjectId'">
                              <span v-for="item in subjectList" v-if="item.id+'' === scope.row.subjectIds">{{item.subjectName}}</span>
                          </div>
                          <span v-if="scope.row.tson && scope.row.tson[col.name]">{{ scope.row.tson[col.name] }}</span>
                          <span v-else>{{ scope.row[col.name] }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column align="center" fixed="right" width="150" label="操作">
                      <template slot-scope="slot">
                          <el-button size="mini" v-permission="'crm:cardSea:receive'" @click="drawOppor(slot.row.id)">领取</el-button>
                          <el-button v-permission="'crm:cardSea:allotOne'"  size="mini" type="success" @click="allot(slot.row)">分配</el-button>
                      </template>
                  </el-table-column>
              </el-table>
              <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="page.current"
                      :page-sizes="[10, 20, 30, 200]"
                      :page-size="page.size"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="page.total">
              </el-pagination>

              <AllotOrUpdatePossess :opportunity-list="allotOrUpdateOrPossessObj.opportunityList"
                                    :type="allotOrUpdateOrPossessObj.type"
                                    :show-obj="allotOrUpdateOrPossessObj.showObj"
                                    :choose-list="allotOrUpdateOrPossessObj.chooseList"
                                    @reload="reload">
              </AllotOrUpdatePossess>
              <ShowTelephone :opportunity-list="showTelephoneObj.opportunityList"
                             :show-obj="showTelephoneObj.showObj">

              </ShowTelephone>
              <TheAddOpportunity
                    :pond-id="1"
                      @reload="reload"
                      :subject-list="subjectList"
                      :possess-list="possessList"
                      :adviser="0"
                      :is-show-add-oppor="addOpporObj.showObj">
              </TheAddOpportunity>
              <ImportOpportunity :type="2" :show-obj="importObj.showObj" :token="importObj.token"></ImportOpportunity>
          </el-tab-pane>

          <el-tab-pane v-if="isShow('crm:cardSea:delete')" label="已删除名片信息" name="second">
              <el-button v-permission="'crm:cardSea:delete'" type="primary" size="mini" @click="resetDeleted" v-if="!loading">恢复</el-button>
              <el-table :data="opporTableData" style="width: 100%" stripe fit @select="selectRow" @select-all="selectAll"
                        max-height="800"
                        @selection-change="selectChange" v-loading="loading">
                  <el-table-column align="center" type="selection" fixed="left"></el-table-column>
<!--                  <el-table-column align="center" prop="subjectIds" label="咨询科目">-->
<!--                      <template slot-scope="scope">-->
<!--                          <div v-for="item in subjectList" v-if="item.id+'' === scope.row.subjectIds">{{item.subjectName}}</div>-->
<!--                      </template>-->
<!--                  </el-table-column>-->
                  <el-table-column align="center"  label="状态">
                      <el-tag type="danger">已删除</el-tag>
                  </el-table-column>
                  <el-table-column align="center" label="操作人">
                      <template slot-scope="scope">
                          <el-tag type="warning">{{scope.row.updateBy}}</el-tag>
                      </template>
                  </el-table-column>
                  <el-table-column align="center" prop="updateTime" label="删除时间" width="150">
                  </el-table-column>
                  <el-table-column v-for="(col,index) in columns" :prop="col.name"
                                   :label="col.label" width="150" align="center">
                      <template slot-scope="scope">
                          <div v-if="col.name === 'subjectId'">
                              <span v-for="item in subjectList" v-if="item.id+'' === scope.row.subjectIds">{{item.subjectName}}</span>
                          </div>
                          <span v-if="scope.row.tson && scope.row.tson[col.name]">{{ scope.row.tson[col.name] }}</span>
                          <span v-else>{{ scope.row[col.name] }}</span>
                      </template>
                  </el-table-column>
              </el-table>
              <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="page.current"
                      :page-sizes="[10, 20, 30, 200]"
                      :page-size="page.size"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="page.total">
              </el-pagination>
          </el-tab-pane>
      </el-tabs>
  </div>
</template>

<script>
import AllotOrUpdatePossess from "./components/AllotOrUpdatePossess";
import * as api from "../../utils/api";
import ShowTelephone from "./components/ShowTelephone";
import ImportOpportunity from "./components/ImportOpportunity";
import * as searchApi from "@/api/system/searchDeploy";
import TheAddOpportunity from "@/components/opportunity/TheAddOpportunity";
import * as accountApi from "@/api/system/account";
import {permissionCommon} from "@/utils/utils";

export default {
    name: "CardSea",
    components: {
        ShowTelephone,
        AllotOrUpdatePossess,
        ImportOpportunity,
        TheAddOpportunity
    },
    data() {
        return {
            activeName:'first',
            opporTableData: [],
            allotOrUpdateOrPossessObj: {
                opportunityList: [],//分配或修改唯一配置的list
                chooseList: [],// 学习顾问列表，或者 唯一配置列表
                type: 1,
                showObj: {
                    isShow: false
                }
            },
            showTelephoneObj: {
                opportunityList: [],//查看手机号
                showObj: {
                    isShow: false
                }
            },
            addOpporObj: {
                showObj: {
                    isShow: false
                },
                possessList: []
            },
            importObj: {
                showObj: {
                    isShow: false
                },
                token: ""
            },
            possessList: [],
            subjectList: [],
            condition: {
                isDelete: 1
            },
            page: {current: 1, size: 10, total: 0},
            multipleSelection: [],
            loading: true,
            columns: [],
        };
    },
    computed: {
        isShow() {
            return (permission => {
                return permissionCommon(permission)
            })
        }
    },
    methods: {
        keyboard(e) {
            if (e.keyCode === 13) {
                this.doSearch()
            }
        },
        getColumn: function () {
            searchApi.findByClassify("SEA_TABLES").then(res => {
                if (res.data) {
                    let entity = res.data;
                    this.columns = entity;
                }
            });
        },
        handleSizeChange(val) {
            this.page.size = val
            this.getOpportunityList(this.condition)
        },
        handleCurrentChange(val) {
            this.page.current = val
            this.getOpportunityList(this.condition)
        },
        selectRow(select, row) {
            this.multipleSelection.push({id: row.id, telephone: row.telephone, kcuserId: row.kcuserId})
        },
        selectAll(select) {
            this.multipleSelection = select.map(res => {
                return {id: res.id, telephone: res.telephone, kcuserId: res.kcuserId}
            })
        },
        /**
         * 取消选择
         */
        selectChange(selection) {
            this.multipleSelection = selection.map(res => {
                return {id: res.id, telephone: res.telephone, kcuserId: res.kcuserId}
            })
        },
        /**
         * 获取唯一配置列表
         */
        getPossessList: function () {
            api.possessList().then(res => {
                if (res.success) {
                    this.possessList = res.data
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.error("获取唯一配置失败");
            })

        },
        /**
         * 获取专业列表
         */
        getSubjectList: function () {
            api.subjectList().then(res => {
                if (res.success) {
                    this.subjectList = res.data
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.error("获取专业列表失败");
            })
        },
        /**
         * 获取机会列表
         * @param params
         */
        getOpportunityList: function (params) {
            if(!params){
                params = {}
            }
            if (params.telephone) {
                let telephone = params.telephone.trim()
                telephone = telephone.replace("​", "")
                if (telephone.length > 11) {
                    this.$message.warning("手机号长度错误!");
                    this.loading = false
                    return
                }
                params.telephone = telephone
            }
            params['pondId'] = 1
            api.cardSeaOpporListJson(this.page.current, this.page.size, params).then(res => {
                if (res.success) {
                    let resEntity = res.data
                    this.page.current = resEntity.current
                    this.page.size = resEntity.size
                    this.page.total = resEntity.total
                    let r = resEntity.records
                    for (let i in r) {
                        let extra = r[i]['extra']
                        if (extra) {
                            let tson = JSON.parse(extra)
                            r[i]["tson"] = tson
                        }
                    }
                    this.opporTableData = r
                } else {
                    this.$message.warning(res.msg);
                }
                this.loading = false
            }).catch(() => {
                this.loading = false
                this.$message.error("获取数据列表失败")
            })
        },
        dueConditionTime: function () {
            if (this.condition.uploadTime) {
                let uploadTime = this.condition.uploadTime
                let createTimeStart = uploadTime[0].format("yyyy-MM-dd HH:mm:ss")
                let createTimeEnd = uploadTime[1].format("yyyy-MM-dd HH:mm:ss")
                this.condition.createTimeStart = createTimeStart
                this.condition.createTimeEnd = createTimeEnd
            } else {
                this.condition.createTimeStart = ""
                this.condition.createTimeEnd = ""
            }

            if (this.condition.allotTime) {
                let allotTime = this.condition.allotTime
                let vallotTimeStart = allotTime[0].format("yyyy-MM-dd HH:mm:ss")
                let vallotTimeEnd = allotTime[1].format("yyyy-MM-dd HH:mm:ss")
                this.condition.vallotTimeStart = vallotTimeStart
                this.condition.vallotTimeEnd = vallotTimeEnd
            } else {
                this.condition.vallotTimeStart = ""
                this.condition.vallotTimeEnd = ""
            }
            if (this.condition.collectionTime) {
                let collectionTime = this.condition.collectionTime
                let collectionTimeStart = collectionTime[0].format("yyyy-MM-dd HH:mm:ss")
                let collectionTimeEnd = collectionTime[1].format("yyyy-MM-dd HH:mm:ss")
                this.condition.collectionTimeStart = collectionTimeStart
                this.condition.collectionTimeEnd = collectionTimeEnd
            } else {
                this.condition.collectionTimeStart = ""
                this.condition.collectionTimeEnd = ""
            }

            if (this.condition.backStoreTimeStr) {
                let backStoreTime = this.condition.backStoreTimeStr
                let backStoreTimeStart = backStoreTime[0].format("yyyy-MM-dd HH:mm:ss")
                let backStoreTimeEnd = backStoreTime[1].format("yyyy-MM-dd HH:mm:ss")
                this.condition.backStoreTimeStart = backStoreTimeStart
                this.condition.backStoreTimeEnd = backStoreTimeEnd
            } else {
                this.condition.backStoreTimeStart = ""
                this.condition.backStoreTimeEnd = ""
            }

        },
        /**
         * 搜索
         */
        doSearch() {
            this.page.current = 1
            this.dueConditionTime()
            this.getOpportunityList(this.condition)
        },
        /**
         * 分配
         * @param row
         */
        allot(row) {
            this.allotOrUpdateOrPossessObj.opportunityList = [{id: row.id, telephone: row.telephone, kcuserId: row.kcuserId}]
            this.allotOrUpdateOrPossessObj.chooseList = this.adviserList
            this.allotOrUpdateOrPossessObj.type = 1
            this.allotOrUpdateOrPossessObj.showObj.isShow = true
        },
        /**
         * 分配
         */
        toAllot() {
            if (!this.multipleSelection.length) {
                this.$message.warning("当前未选择任何分配的机会")
                return
            }
            this.allotOrUpdateOrPossessObj.chooseList = this.adviserList
            this.allotOrUpdateOrPossessObj.opportunityList = this.multipleSelection
            this.allotOrUpdateOrPossessObj.type = 1
            this.allotOrUpdateOrPossessObj.showObj.isShow = true
        },
        /**
         * 修改唯一配置
         */
        toUpdatePossess() {
            if (!this.multipleSelection.length) {
                this.$message.warning("当前未选择任何修改的机会")
                return
            }
            this.allotOrUpdateOrPossessObj.chooseList = this.possessList
            this.allotOrUpdateOrPossessObj.opportunityList = this.multipleSelection
            this.allotOrUpdateOrPossessObj.type = 2
            this.allotOrUpdateOrPossessObj.showObj.isShow = true
        },
        /**
         *展示手机号
         */
        showTelephone() {
            if (!this.multipleSelection.length) {
                this.$message.warning("未选择要查看的手机号的机会")
                return
            }
            let idArr = this.multipleSelection.map(res => {
                return res.id
            })
            api.showTelephone(idArr).then(res => {
                if (res.success) {
                    this.showTelephoneObj.opportunityList = res.data
                    this.showTelephoneObj.showObj.isShow = true
                } else {
                    this.$message.warning("查看手机号失败")
                }
            }).catch(error => {
                this.$message.error("查看手机号失败");
            })
        },
        /**
         * 领取机会
         */
        drawOppor(id) {
            api.draw(id, 4).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$message.error("请求失败,请稍后重试!")
            })
        },
        /**
         * 导入机会
         */
        importOppor() {
            api.token().then(res => {
                this.importObj.token = res
                this.importObj.showObj.isShow = true
            })
        },
        /**
         * 获取学习顾问列表
         */
        getAdviserList: function () {
            accountApi.adviserList().then(res => {
                if (res.success) {
                    this.adviserList = res.data
                }
            })
        },
        /**
         * 删除机会
         */
        toRecycleBin() {
            if (!this.multipleSelection.length) {
                this.$message.warning("请至少选择一条要删除的机会")
                return
            }
            let params = this.multipleSelection.map(r => {
                return r.id
            })

            this.$confirm(`真的要删除这 ${this.multipleSelection.length} 条机会吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api.toRecycleBin(params).then(res => {
                    if (res.success) {
                        this.$message.success(res.msg)
                        this.getOpportunityList(this.condition)
                    } else {
                        this.$message.error(res.msg)
                    }
                }).catch(() => {
                    this.$message.error("删除失败,请稍后重试")
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        /**
         * 重新刷新页面
         * @param params
         */
        reload() {
            this.loading = true
            this.getOpportunityList(this.condition)
        },
        resetQuery() {
            this.condition = {}
            this.reload()
        },
        handleClick(tab, event) {
            //first表示没有删除
            this.condition = {};
            if (this.activeName === 'first'){
                this.condition.isDelete = 1
            }else if(this.activeName ==='second'){
                this.condition.isDelete = 0
            }
            this.loading = true;
            this.getOpportunityList(this.condition);
        },
        /**
         * 将删除的重置回来
         * @returns {ElMessageComponent}
         */
        resetDeleted() {
            if (!this.multipleSelection.length) {
                return this.$message.warning("请至少选择一条机会来恢复！！！");
            }
            let params = this.multipleSelection.map(r => {
                return r.id
            })

            this.$confirm(`真的要恢复这个 ${this.multipleSelection.length} 条机会吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api.resetOpportunity(params).then(res => {
                    if (res.success) {
                        this.$message.success(res.msg)
                        this.getOpportunityList(this.condition)
                    } else {
                        this.$message.error(res.msg)
                    }
                }).catch(() => {
                    this.$message.error("删除失败,请稍后重试")
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
    },
    mounted() {
        // window.addEventListener('keydown', this.keyboard, true)//开启监听键盘按下事件
        this.getAdviserList()
        this.getPossessList()
        this.getSubjectList()
        this.getOpportunityList(this.condition)
        this.getColumn()
    }
}
</script>

<style scoped>

/deep/ .el-form-item {
    margin-bottom: 0;
    vertical-align: middle;
}
</style>
